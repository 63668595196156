import * as React from "react"
import PropTypes from "prop-types"

import Layout from "../components/Layout/Layout"
import SeoHead from "../components/Scripts/SeoHead"

const NotFoundPage = ({ pageContext }) => {
  const { globalComponents } = pageContext
  return (
    <Layout globalComponents={globalComponents} isNotFoundPage={true}>
      <div className="max-w-[960px] mx-auto pt-50 px-20 lg:px-30">
      <h1 className="text-40 leading-[36px] font-alternate text-textBlack">PAGE NOT FOUND</h1>
      <p className="text-textBlack pt-50 pb-40 leading-[1.375]">
        There is a problem with the page you are trying to reach and it cannot
        be displayed
      </p>
      </div>
    </Layout>
  )
}

export const Head = () => <SeoHead title="Cocoa Life - Page not found" />
NotFoundPage.propTypes = {
  pageContext: PropTypes.object,
  globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}
export default NotFoundPage
